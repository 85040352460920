<template>
  <div class="moreLists">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">榜单详情</div>
      <div class="rightBtn"></div>
    </div>
    <van-tabs
      v-model="activeName"
      background="#fff"
      title-active-color="rgb(255,103,143)"
      title-inactive-color="rgb(51,51,51)"
      class="vant_tabs"
      animated
      :swipeable="true"
    >
      <van-tab :title="item.typeDesc" v-for="item in list" :key="item.type" :name="item.type">
        <div class="leaderboardBox" v-if="item.members && item.members.length > 0">
          <div class="leaderboarItem" v-for="(i, j) in item.members" :key="i.id">
            <svg-icon v-if="j < 3" class="rankIcon" :iconClass="'top' + (j + 1)"></svg-icon>
            <div class="rankIcon" v-else>{{ j + 1 }}</div>
            <router-link tag="div" :to="`/userHomePage?uid=${i.id}`" class="avatarBox" :class="{ isCertifica: i.merchantUser == 1 }">
              <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="i.merchantUser == 1" />
              <ImgDecypt class="avatar" round :src="i.avatar" />
              <div class="certification" v-if="i.superUser == 1">
                <Vlogo :vid="i.id + '' + item.type" />
              </div>
            </router-link>
            <div class="leaderboarData">
              <div class="userName" :class="{ vipName: checkVip(i) }">
                {{ i.name }}
              </div>
              <div class="value" v-if="item.typeDesc == '周关注榜'">
                {{ i.value + ' 关注' }}
              </div>
              <div class="value" v-else>
                {{ Number(i.value).toFixed(1) + ' 金币' }}
              </div>
            </div>
          </div>
        </div>
        <NoData v-else />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import NoData from '@/components/NoData';
import { getLeaderboard } from '@/api/mine';
import { Tab, Tabs } from 'vant';
export default {
  name: 'MoreLists',
  components: {
    Vlogo,
    NoData,
    ImgDecypt,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      activeName: 0,
      list: [],
    };
  },
  created() {
    this.queryLeaderboard();
  },
  methods: {
    async queryLeaderboard() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(getLeaderboard);
        this.activeName = this.$route.query.type ? Number(this.$route.query.type) : 0;
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          this.list = res.data.list;
          console.log(this.list);
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    // 校验是否会员、会员时间是否到期
    checkVip(item) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(item.vipExpireDate).getTime();
      if (item && item.vipLevel > 0 && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.moreLists {
  overflow: hidden;
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .vipName {
    color: #ff678f !important;
  }

  .bankBtn {
    position: fixed;
    top: 10px;
    left: 16px;
    z-index: 9;
    width: 24px;
    height: 24px;
  }

  .vant_tabs {
    height: 100%;

    /deep/ .van-tabs__wrap {
      height: 36px;
      margin: 0 auto;
    }

    /deep/ .van-tab__text {
      font-size: 16px;
    }

    /deep/ .van-tabs__line {
      height: 4px;
      width: 24px;
      background: #ff678f;
    }

    /deep/ .van-tabs__nav--line {
      padding-bottom: 16px;
    }

    /deep/ .van-tab__pane {
      height: 100%;
    }

    /deep/ .van-tab__pane-wrapper {
      height: 100%;
    }

    /deep/ .van-tabs__track {
      height: 100%;
    }

    /deep/ .van-tabs__content {
      height: 100%;
    }
  }

  .leaderboardBox {
    height: calc(100% - 80px);
    overflow-y: auto;
    padding: 0 16px;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;

    .leaderboarItem:last-child {
      margin-bottom: 24px;
    }

    .leaderboarItem {
      display: flex;
      align-items: center;
      margin-top: 10px;
      background-color: #fff;
      padding: 16px 20px;
      border-radius: 4px;
      .rankIcon {
        width: 26px;
        height: 26px;
        margin-right: 20px;
        line-height: 26px;
        text-align: center;
      }

      .avatarBox {
        position: relative;
        width: 50px;
        height: 50px;
        margin-right: 10px;
        .avatar {
          width: 50px;
          height: 50px;
        }
        .certification {
          width: 14px;
          height: 14px;
          position: absolute;
          right: 0;
          bottom: 0;
          overflow: hidden;
          border-radius: 50%;
          z-index: 2;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
        }
        .certificaBorder {
          position: absolute;
          top: -8.2px;
          width: 100%;
          font-size: 25px;
          z-index: 1;
        }
      }

      .isCertifica {
        border-radius: 50%;
        border: 1.5px solid #d82d0b;
      }

      .leaderboarData {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .userName {
          font-size: 16px;
        }

        .value {
          font-size: 12px;
          padding: 3px 0;
          color: #fff;
          border-radius: 6px;
          color: rgb(255, 135, 42);
          // background-image: linear-gradient(to right, rgb(255, 135, 42), rgb(255, 181, 124));
        }
      }
    }
  }
}
</style>
